import React, { useState } from "react";
import Image from "gatsby-image";
import { graphql, Link } from "gatsby";
import { Modal } from "react-bootstrap";
// import Img from "gatsby-image";

import useSetPageData from "../../utils/useSetPageData";
import SEO from "../../components/seo";

import ListView from "../ListView";
import Hero from "../../components/panels/Hero";
import HubSpotForm from "../../components/non-panels/forms/HubSpotForm";

// okay
import s from "./BlogList-v2.module.scss";

// Blog info bar short form.
export const BlogSubscribeFormReplacementMessage = () => (
  <div>
    <p>
      <span>Done!</span>Thank you so much for your subscription.
    </p>
  </div>
);

function extractPostData({ edges }) {
  edges = edges.sort((a, b) => {
    let date1 = a.node["publish_date"] || a.node["created_at"];
    let date2 = b.node["publish_date"] || b.node["created_at"];
    return new Date(date2) - new Date(date1);
  });
  return edges.map(({ node }) => ({ ...node, slug: node.slug + "/" }));
}

export default function MediaList({
  location,
  data: { pageBlogPosts, recentBlogPosts, strapiPnBlog },
  pageContext: { slugToURIMap, baseURI, numPages, currentPage }
}) {
  let { SEO: seo, hero } = strapiPnBlog;
  hero = hero[0];

  const posts = pageBlogPosts;
  const recentPosts = recentBlogPosts;
  const icon = hero.insight_image.childImageSharp.fixed;
  const [postList, recentPostList] = [posts, recentPosts].map(extractPostData);

  useSetPageData(location);

  // console.log("numPages", numPages, new Array(numPages).fill("data"));

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  return (
    <>
      <SEO
        {...{
          title: `${hero.main_heading} | Insights`,
          description: seo.description
        }}
      />
      <Hero heading={hero.main_heading} icon={icon} />
      <div className={`container-fluid panel-padding`}>
        <div className="container px-4">
          <div className="row gx-5 p-3">
            <div className="col">
              <h3 className={`mb-0 text-primary`}>
                Does technology fascinate you?
                <div className="btn btn-primary ml-4" onClick={handleShowModal}>
                  Subscribe
                </div>
              </h3>
            </div>
          </div>
        </div>
        <div className={`container`}>
          <div className="row gx-5 p-3 mt-4 mb-4">
            {postList.map(post => (
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/blog/${post.slug}`}
                >
                  <div className={`card mb-5 ${s.card}`}>
                    <Image
                      className={`float-right card-img-top ${s.image}`}
                      fluid={post.blog_cover_image.childImageSharp.fluid}
                    />
                    <div className="card-body">
                      <h4 className="card-title">
                        <small className={s.cardTitle}>{post.heading}</small>
                      </h4>
                      <hr className={s.divider}></hr>
                      <p className="card-text text-muted">
                        <bold>
                          {post.description.substring(0, 200) + "..."}
                        </bold>
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
        {/* <div className={`container`}>
          <div className="row gx-5 p-3 mb-4">
            <div className={`col`}>

              <div class="btn-group" role="group" aria-label="Horizontal Button Group">
                {new Array(numPages).fill("data").map((pageNumber, i) => {
                  if (i === 0) {
                    return (<>
                      <Link className={`btn btn-primary ${currentPage === i ? "btn-light" : ""}`} to={`/blog/`}>{i + 1}</Link>
                    </>)
                  } else {
                    return (<>
                      <Link className={`btn btn-primary ${currentPage === i ? "btn-light" : ""}`} to={`/blog/${i + 1}`}>{i + 1}</Link>
                    </>)
                  }
                })}
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Subscribe</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* <div className={`btn btn-danger float-right`} onClick={handleCloseModal}>X</div> */}
          {/* <p>Modal body text goes here.</p> */}

          <HubSpotForm
            formId="d0f017e3-30cf-4cf7-98ea-b2b1fce2eec8"
            replacementElement={<BlogSubscribeFormReplacementMessage />}
          />
        </Modal.Body>

        {/* <Modal.Footer>
          <Button variant="secondary">Close</Button>
          <Button variant="primary">Save changes</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export const mediaListQuery = graphql`
  query blogListQueryV2($skip: Int!, $limit: Int!, $recentLimit: Int!) {
    pageBlogPosts: allStrapiPostBlog(
      sort: { fields: [created_at], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          heading
          description
          id
          slug
          created_at
          publish_date
          content
          blog_cover_image {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    recentBlogPosts: allStrapiPostBlog(
      sort: { fields: [created_at], order: DESC }
      limit: $recentLimit
    ) {
      edges {
        node {
          heading
          id
          slug
          created_at
          publish_date
        }
      }
    }
    strapiPnBlog {
      SEO {
        description
      }
      hero {
        main_heading
        main_subheading
        insight_image {
          childImageSharp {
            fixed(width: 100, height: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;
